import * as $ from 'jquery';

let initBackToTop = () => {
	let bigText = document.querySelector('.magazine_article') || document.querySelector('.blog_article')
	if (bigText){
		let backToTop = document.querySelector('.back-to-top')
		let backToTopInner = document.querySelector('.back-to-top_inner')
		let footer = document.querySelector('.footer')

		if (backToTop){
			document.addEventListener('scroll',  () => {
				if (window.scrollY < 100){
					backToTop.style.visibility = 'hidden'
					backToTop.style.opacity = 0
				} else {
					backToTop.style.visibility = 'visible'
					backToTop.style.opacity = 1
				}
				if (window.scrollY > footer.getBoundingClientRect().y - window.innerHeight + window.scrollY){
					backToTopInner.style.position = 'absolute'
				} else {
					backToTopInner.style.position = 'fixed'
				}
			})
			backToTopInner.addEventListener('click', () => {
				$("html, body").animate({ scrollTop: 0 }, 600, 'swing');
			})
		}
	}
}



document.addEventListener('turbolinks:load', () => {
	initBackToTop()
})