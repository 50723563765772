let initFullScreen = () => {
	let textBlock = document.querySelector('.formatted-text')
	if (textBlock){
		let textImages = textBlock.querySelectorAll('img')
		let widePopup = document.querySelector('.wide-popup')
		let widePopupImg = document.querySelector('#js-wide-popup-image')
		let closewidePopup = document.querySelector('.js-close-wide-popup')
		for (var i = textImages.length - 1; i >= 0; i--) {
			textImages[i].addEventListener('click', (e) => {
				let src = e.target.getAttribute('src')
				widePopupImg.src = src
				widePopup.classList.add('is-active')
				document.querySelector('html').classList.add('noscroll')
			})
		}

		closewidePopup.addEventListener('click', () => {
			widePopup.classList.remove('is-active')
			document.querySelector('html').classList.remove('noscroll')
		})
		document.addEventListener('keydown', (e) =>{
			if((e.key=='Escape'||e.key=='Esc'||e.keyCode==27) ){
				if (widePopup.classList.contains('is-active')){
					widePopup.classList.remove('is-active')
					document.querySelector('html').classList.remove('noscroll')
				}
			}
		} )
	}
}




document.addEventListener('turbolinks:load', () => {
	initFullScreen()
})