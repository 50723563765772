import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

document.addEventListener('turbolinks:load', () => {
	imagesLoaded('body', () => {
		let grid = document.querySelector('.masonry')
		if (grid){
			let msnry = new Masonry( grid, {
				itemSelector: '.masonry_item',
				percentPosition: true
			})
		}
	})
	
})