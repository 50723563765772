import imagesLoaded from 'imagesloaded'


// ADD CLASS lazyload for images
// add class lazyload-bg for background
// add data-image="new-image" in html
document.addEventListener('turbolinks:load', () => {
	imagesLoaded('body', () => {
		let images = document.querySelectorAll('.lazyload')

		for (var i = images.length - 1; i >= 0; i--) {
			let src = images[i].getAttribute('data-image')
			images[i].src = src
		}
		let bgImages = document.querySelectorAll('.lazyload-bg')

		for (var i = bgImages.length - 1; i >= 0; i--) {
			let src = bgImages[i].getAttribute('data-image')
			bgImages[i].style.backgroundImage = `url('${src}')`
		}
	})
})