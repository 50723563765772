function is_touch_device() {  
  	try {  
    	document.createEvent("TouchEvent");  
    	return true;  
  	} catch (e) {  
    	return false;  
  	}	  
}


let initTouchShare = () => {
	let shareMenuOpened = false
	let shareItemDivs = document.querySelectorAll('.share-item')
	let shareItemIcons = document.querySelectorAll('.download-share_submenu a')

	let toggleMenu = (openMenu) => {
		shareMenuOpened = !shareMenuOpened
		if (shareMenuOpened){
	    	openMenu.className += ' is-active';
	      	document.addEventListener('click', onDoc_click);
	    }
	    else{
	    	openMenu.className = openMenu.className.replace('is-active', '').trim();
	      	document.removeEventListener('click', onDoc_click);
	    }
	}
	let onDoc_click = (domEvent) => {
		domEvent.stopPropagation();
		for (var i = shareItemDivs.length - 1; i >= 0; i--) {
			toggleMenu(shareItemDivs[i])
		}
	}

	if(is_touch_device && shareItemDivs.length > 0){
		for (var i = shareItemDivs.length - 1; i >= 0; i--) {
			shareItemDivs[i].addEventListener('click', (e) => { 
				e.stopPropagation()
				toggleMenu(shareItemDivs[i])
			})
		}
		for (var i = shareItemIcons.length - 1; i >= 0; i--) {
			shareItemIcons[i].addEventListener('click', (e) => {
				e.stopPropagation
			})
		}
	}
	document.addEventListener('click', (e) => {
		e.stopPropagation

	})
}

document.addEventListener('turbolinks:load', () => {
	initTouchShare()
})