let initMenuMobile = () => {
	let hamburger = document.querySelector('.hamburger')
	let menuMobile = document.querySelector('.menu-mobile')
	let openSubmenu = document.querySelectorAll('.js-open-mobile-sub')

	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('is-active')
		menuMobile.classList.toggle('is-active')
		document.querySelector('html').classList.toggle('noscroll')
		document.querySelector('.header').classList.toggle('fixed')
	})

	for (var i = openSubmenu.length - 1; i >= 0; i--) {
		openSubmenu[i].addEventListener('click', (e) => {
			e.currentTarget.classList.toggle('is-active')
		})
	}
}




document.addEventListener('turbolinks:load', () => {
	// when click on mobile menu turbolinks doesn't remove noscroll
	document.querySelector('html').classList.remove('noscroll') 

	initMenuMobile()
})