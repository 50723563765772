function map(x, in_min, in_max, out_min, out_max) {
  return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min
}

initMinimizeHeader = () => {
	let lastScrollTop = 0; 
	let header = document.querySelector('.header')
	let section = document.querySelector('section') 
	let sectionY // reduce header when top scrolls to first sectionY 
			  // header is sticky and if it is reduced window.pageOffsets changes
			  // to restore the variation our threshold is based on a real div 
			  // sectionY changes of the same reduction factor of header

	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	
	window.addEventListener('scroll', (event) => {
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		sectionY = window.pageYOffset + section.getBoundingClientRect().top; 
		if (window.innerWidth > 575){
			if (scrollTop >= sectionY - 50){
	        	header.classList.add('mini')
	        	headerMiniH = header.offsetHeight; 	// avoid flickering by putting 
	   		} else if (scrollTop < sectionY - 50){ 	// a security margin - there are 50 pixel 
	   			header.classList.remove('mini') 	// of distance between add class and remove class
	   		}
	   	}
	})
}

let initSubmenus = () => {
	let submenuTriggers = document.querySelectorAll('.js-open-submenu')

	for (var i = submenuTriggers.length - 1; i >= 0; i--) {
		submenuTriggers[i].addEventListener('click', (e) => {
			id = e.currentTarget.getAttribute('data-sub')
			submenus = document.querySelectorAll('.header_sub')
			if (e.currentTarget.classList.contains('is-active')){
				document.querySelector(`#${id}`).classList.remove('is-active')
				e.currentTarget.classList.remove('is-active')
			} else {
				for (var i = submenus.length - 1; i >= 0; i--) {
					submenus[i].classList.remove('is-active')
				}
				for (var i = submenuTriggers.length - 1; i >= 0; i--) {
					submenuTriggers[i].classList.remove('is-active')
				}
				e.currentTarget.classList.add('is-active')
				document.querySelector(`#${id}`).classList.add('is-active')
				if (id == 'sub-search'){
					document.querySelector('.search-bar_input').focus()
				}
			}
		})
	}
}

let initReadingBar = () => {
	let readingBar = document.querySelector('.reading-bar')
	if(readingBar){
		window.addEventListener('scroll', () => {
			let x = map(window.scrollY, 
						0, document.querySelector('body').scrollHeight - window.innerHeight,
						0, 100)
			readingBar.style.width = `${x}%`; 
		})
	}
}



document.addEventListener('turbolinks:load', () => {
	initMinimizeHeader()
	initSubmenus()
	initReadingBar()
})