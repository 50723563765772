let initSupport = () => {
	let openSupport = document.querySelectorAll('.js-open-support')
	let supportModal = document.querySelector('.support-modal')
	let closeSupport = document.querySelector('.js-close-support')
	
	for (var i = openSupport.length - 1; i >= 0; i--) {
		openSupport[i].addEventListener('click', () => {
			supportModal.classList.add('is-active')
			document.querySelector('html').classList.add('noscroll')
			console.log('click')
		})
	}
	
	closeSupport.addEventListener('click', () => {
		supportModal.classList.remove('is-active')
		document.querySelector('html').classList.remove('noscroll')
	})
}



document.addEventListener('turbolinks:load', () => {
	initSupport()
})