let initSelectorSearchResults = () => {
	let selectors = document.querySelectorAll('.js-open-results')
	let searchResultDivs = document.querySelectorAll('.search-results_container_inner')
	if(selectors){
		for (var i = selectors.length - 1; i >= 0; i--) {
			selectors[i].addEventListener('click', (e) => {
				for (var i = searchResultDivs.length - 1; i >= 0; i--) {
					searchResultDivs[i].classList.remove('is-active')
				}
				for (var i = selectors.length - 1; i >= 0; i--) {
					selectors[i].classList.remove('is-active')
				}
				e.currentTarget.classList.add('is-active')
				let id = e.currentTarget.getAttribute('data-results')
				document.querySelector(`#${id}`).classList.add('is-active')
			})
		}
	}
}

document.addEventListener('turbolinks:load', () => {
	initSelectorSearchResults()
})