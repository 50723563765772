

function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function is_touch_device() {  
  	try {  
    	document.createEvent("TouchEvent");  
    	return true;  
  	} catch (e) {  
    	return false;  
  	}	  
}

initFootnotesHover = () => {
	let formattedText = document.querySelector('.formatted-text')
	let links = formattedText.querySelectorAll('a')
	for (var i = links.length - 1; i >= 0; i--) {
		let id = links[i].getAttribute('id')
		if (id && id.includes('footnote-marker')){
			let idNum = id.split('-')[2]
			let noteText = document.querySelector(`#footnote-${idNum}`).textContent
			let notePanel = document.createElement('div')
			notePanel.classList.add('footnote-panel')
			notePanel.innerHTML += `<div class="txt-5 mini-uppercase font-sans c-grey-dark margin-bottom-2 note-title">Nota - ${idNum}</div>\
			<div class="txt-5 font-sans">${noteText}</div>\
			<div class="close-note c-grey-dark txt-4"><i class="icon-close"></i></div>`
			insertAfter(notePanel, links[i])

			/// DISABLE CLICK ON MOUSE DEVICE ///
			if (!is_touch_device()){
				links[i].addEventListener('click', (e) => {
					e.preventDefault()
				})
			}
			/// GET NOTES IN RIGHT POSITION ///
			if (!is_touch_device()){
				// using setTimeout instead of imagesLoaded
				// there is an error importing imagesLoaded and we do q&d style 
				setTimeout( () => {
					let x = notePanel.getBoundingClientRect().x
					if(window.innerWidth - x < 500){
						notePanel.style.transform = `translateX(${- (508 - (window.innerWidth - x)) }px)`
					}
				}, 600)

			}
			window.addEventListener('resize', () => {
				if (!is_touch_device()){
					let x = notePanel.getBoundingClientRect().x
					if(window.innerWidth - x < 500){
						notePanel.style.transform = `translateX(${- (508 - (window.innerWidth - x)) }px)`
					}
				}
			})

			/// ENABLE TOUCH EVENT ///
			if (is_touch_device()){
				links[i].addEventListener('touchstart', (e) => {
					e.preventDefault()
					setTimeout(() => notePanel.classList.add('is-active'), 300)
				})
				var closeNote = notePanel.querySelector('.close-note')
				closeNote.addEventListener('click', (e) => {
					notePanel.classList.remove('is-active')
				})
			}
			
			/// SHOW HIDE NOTE ///
			links[i].addEventListener('mouseenter', () => {
				notePanel.classList.add('is-active')
			})
			links[i].addEventListener('mouseleave', () => {
				notePanel.classList.remove('is-active')
			})
		}
	}
}



document.addEventListener('turbolinks:load', () => {
	if(document.querySelector('.formatted-text')){
		initFootnotesHover()
	}
})