import $ from 'jquery';
import 'slick-carousel';

document.addEventListener("turbolinks:load", () => {

	if ($(".magazine-issue_carousel_slider") ){
		// inizialize slick
		$(".magazine-issue_carousel_slider").slick({
			infinite: true, 
			slidesToShow: 6, 
			slidesToScroll: 6,
			speed: 500,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4, 
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3, 
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 2, 
						slidesToScroll: 2
					}
				}
			]
		});
}
})
